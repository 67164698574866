<template>
	<div class="accredit_list">
		<div class="status_list">
		    <span :class="item.id === status ? 'active' : '' " @click="chooseStatus(item)" v-for="(item,index) in status_list" :key="index">{{item.name}}</span>
		</div>
		<div class="list_tit">
			<div class="item">编号</div>
			<div class="item">名称</div>
			<div class="item">类别</div>
			<div class="item">注册号</div>
			<div class="item">日期</div>
			<div class="line item">期限</div>
			<div class="item">状态</div>
			<div class="item">操作</div>
		</div>
		<div v-loading="loading">
			<div class="item_area" v-for="(item,index) in accredit_list" :key="index">
				<div class="zzc" @click="closeAccredit(item)" v-show="item.is_show"></div>
				<div class="list_item">
					<div class="item">{{item.order_no}}</div>
					<div class="item">{{item.order_name}}</div>
					<div class="item">{{item.order_goods[0].category_name}}</div>
					<div class="item">{{item.order_goods[0].reg_no}}</div>
					<div class="item">{{item.auth_date}}</div>
					<div class="line item">{{item.auth_begin_date}}至{{item.auth_end_date}}</div>
					<div class="item">{{showOrderStatus(item)}}</div>
					<div class="item" @click="toShowAccredit(item)">编辑</div>
				</div>
				<div class="accredit_info" v-if="item.is_show" v-loading="templateListLoading" element-loading-text="证书生成中，请稍等">
					<div v-if="current_order.is_use_template" class="content">
						<el-carousel :autoplay="false" :loop="true" indicator-position="outside">
							<el-carousel-item v-for="(dItem,dIndex) in current_order_file_show" :key="dIndex">
								<div class="data_item" v-for="(sItem,sIndex) in dItem" :key="sIndex">
									<template v-if="sItem.type == 'img'">
										<img :src="$util.img(sItem.file_path)" alt="">
									</template>
									<template v-else-if="sItem.type == 'pdf'">
										<div>{{sItem.file_name}}</div>
									</template>
									<template v-else-if="sItem.type == 'text'">
										<div v-html="sItem.content"></div>
									</template>
								</div>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="choose_content" :style=" contentHeight ? 'height:'+ contentHeight+'px' : 'height:auto'" v-else>
						<div class="template_list">
							<div class="template_tit">模板</div>
							<div class="item" v-for="(aItem,aIndex) in authTemplateList" :key="aIndex" @click="changeTemplate(aItem)" :class="aItem.id == curTemplateId ? 'active' : '' ">
								<div class="pic">
									<img class="pic_bg" :src="$util.img(aItem.auth_letter_img)" alt="">
								</div>
								<div class="name">{{aItem.template_name}}</div>
							</div>
						</div>
						<div class="template_content" v-if="curAuthTemplateImg">
							<!-- <div class="content-area"  ref="contentArea">
								<div v-html="current_template" class="content"></div>
								<div class="content-bg" :style="{'background-image':'url('+ $img(current_watermark) +')'}"></div>
							</div> -->
							<img class="pic_bg" :src="$util.img(curAuthTemplateImg)" alt="">
						</div>
					</div>
					<div class="actions">
						<template v-if="current_order.order_status">
							<template v-if="current_order.refund_status==0 || current_order.refund_status==3">
								<div class="action" @click="downFile()">下载</div>
							</template>
							<div class="action" v-for="(aItem,aIndex) in current_order.refund_status_action" :key="aIndex" @click="dealRefundData(aItem.action)">{{aItem.title}}</div>
						</template>
						<template v-else>
							<template v-if="role=='buyer'">
								<div class="action" @click="toPayAccredit('balance')">余额</div>
								<div class="action" @click="toPayAccredit('wechat')">微信</div>
								<div class="action" @click="toPayAccredit('alipay')">支付宝</div>
							</template>
						</template>
					</div>
				</div>
			</div>
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					layout="prev,pager,next,jumper,total"
					:current-page.sync="currentPage" 
					:page-size.sync="page_size" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page>
				</el-pagination>
			</div>
		</div>
		<el-dialog class="pay_qrcode" :show-close="false" :visible.sync="qrcodeShow" :close-on-click-modal="false">
			<div class="tit">{{qrcode_type=='alipay'?'支付宝':'微信'}}<div class="line"></div></div>
			<img class="qrcode" :src="qrcode_img" />
			<div class="tips">打开{{qrcode_type=='alipay'?'支付宝':'微信'}}支付</div>
			<i class="el-icon-error" @click="closePayQrcode()"></i>
		</el-dialog>
		<iframe id="iframe" class="iframe-style" v-if="iframe_src" :src="iframe_src" ></iframe>
	</div>
</template>

<script>
	import Config from "@/utils/config.js"
	import { orderStatus, orderList, useTemplate, orderDetail, orderPay, orderWechatPay, orderAliPay, base64Qrcode, getPayStatus, applyRefund, cancelRefund, agreeRefund, refuseRefund,download, hasAuthLetter, orderAuthLetterList, checkIsComplete } from '@/api/accredit.js'
	export default {
		data() {
			return {
				iframe_src:'',
				status_list:[],
				status:'all',
				accredit_list:[],
				currentPage:1,
				page_size:10,
				total:0,
				loading:true,
				current_order_file:[],
				current_order_file_show:[],
				current_order:{},
				current_template:'',
				current_watermark:'',
				current_shop_template_id:'',
				qrcodeShow:false,
				qrcode_type:'',
				qrcode_img:'',
				timer:'',
				contentHeight:'',
				authTemplateList: [],
				curAuthTemplateImg:'',
				curTemplateId:'',
				templateListLoading:true
			}
		},
		props: {
			role: {
				type: String,
				default: 'buyer',
			},
		},
		created() {
			this.getAccreditStatus();
			this.getAccreditList();
		},
		methods: {
			closePayQrcode(){
				this.qrcodeShow = false;
				clearTimeout(this.timer);
			},
			showOrderStatus(data){
				var status = data.order_status_name;
				if(data.refund_status == 0){
					status = data.order_status_name;
				}else if(data.refund_status == 3){
					status = data.refund_status_name;
				}else if(data.refund_status == 1){
					status = data.refund_status_name;
				}else if(data.refund_status == 2){
					status = data.refund_status_name;
				}
				return status;
			},
			dealRefundData(action){
				switch (action){
					case 'applyRefund':
						applyRefund({
							order_id:this.current_order.order_id
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({
									message: "申请成功",
									type: "success"
								})
								this.refresh();
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
						break;
					case 'cancelRefund':
						cancelRefund({
							order_id:this.current_order.order_id
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({
									message: "取消成功",
									type: "success"
								})
								this.refresh();
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
						break;
					case 'agreeRefund':
						agreeRefund({
							order_id:this.current_order.order_id
						})
						.then(res=>{
							if(res.code>=0){
								this.refresh();
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
						break;
					case 'refuseRefund':
						refuseRefund({
							order_id:this.current_order.order_id
						})
						.then(res=>{
							if(res.code>=0){
								this.refresh();
							}else{
								this.$message.error(res.message)
							}
						})
						.catch(err=>{
							this.$message.error(err.message)
						})
						break;
					default:
						break;
				}
			},
			getOrderPayStatus(out_trade_no){
				getPayStatus({
					out_trade_no:out_trade_no
				})
				.then(res=>{
					if(res.code>=0){
						if(res.data.pay_status){
							this.$message.success('支付成功')
							clearTimeout(this.timer);
							this.qrcodeShow = false;
							this.qrcode_type = '';
							this.refresh();
						}else{
							this.timer = setTimeout(()=>{
								this.getOrderPayStatus(out_trade_no);
							},1000)
						}
					}else{
						this.$message.error(res.message);
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			toPayAccredit(type){
				switch (type){
					case 'balance':
						orderPay({
							order_id:this.current_order.order_id
						})
						.then(res=>{
							if(res.code>=0){
								this.$message({
									message:"支付成功",
									type:"success"
								})
								this.refresh()
							}else{
								this.$message.error(res.message);
							}
						})
						.catch(err=>{
							this.$message.error(err.message);
						})
						break;
					case 'wechat':
						orderWechatPay({
							order_id:this.current_order.order_id,
							pay_type:'wechatpay'
						})
						.then(res=>{
							if(res.code>=0){
								if(res.data){
									if(res.data.type == 'url'){
										
									}else{
										this.qrcode_type = 'wechat';
										this.qrcode_img = res.data.qrcode;
										this.qrcodeShow = true;
									}
									this.getOrderPayStatus(res.data.out_trade_no);
								}else{
									this.$message({
										message:"支付成功",
										type:"success"
									})
									this.refresh()
								}
							}else{
								this.$message.error(res.message);
							}
						})
						.catch(err=>{
							this.$message.error(err.message);
						})
						break;
					case 'alipay':
						orderAliPay({
							order_id:this.current_order.order_id,
							pay_type:'alipay'
						})
						.then(res=>{
							if(res.code>=0){
								if(res.data){
									if(res.data.type == 'url'){
										base64Qrcode({
											text:res.data.data
										})
										.then(res=>{
											if(res.code>=0){
												this.qrcode_type = 'alipay';
												this.qrcode_img = res.data;
												this.qrcodeShow = true;
											}
										})
									}
									this.getOrderPayStatus(res.data.out_trade_no);
								}else{
									this.$message({
										message:"支付成功",
										type:"success"
									})
									this.refresh();
								}
							}else{
								this.$message.error(res.message);
							}
						})
						.catch(err=>{
							this.$message.error(err.message);
						})
						break;
					default:
						break;
				}
			},
			getOrderDetail(mode='',order){
				orderDetail({
					order_id:this.current_order.order_id,
					role:this.role
				})
				.then(res=>{
					if(res.data){
						var arr = [];
						var show_arr = [];
						// auth_letter_template_list
						// if(res.data.auth_letter_template_list.length){
						// 	var url = this.$util.img( res.data.auth_letter_template_list[0].watermark );
						// 	if( location.protocol.indexOf('http:') != -1 ){
						// 		if(url.indexOf('https:') != -1){
						// 			url = url.replace('https:','http:');
						// 		}
						// 	}else{
						// 		if(url.indexOf('http:') != -1){
						// 			url = url.replace('http:','https:');
						// 		}
						// 	}
						// 	// res.data.auth_letter_template_list[0].name
						// 	show_arr.push({file_path:url,file_name:'授权书.pdf',type:'pdf'});
						// }
						if(res.data.auth_letter_image){
							res.data.auth_letter_image.split(',').forEach(item=>{
								var url = item;
								// if( location.protocol.indexOf('http:') != -1 ){
								// 	if(url.indexOf('https:') != -1){
								// 		url = url.replace('https:','http:');
								// 	}else{
								// 		url = this.$config.baseUrl + url;
								// 	}
								// }else{
								// 	if(url.indexOf('http:') != -1){
								// 		url = url.replace('http:','https:')
								// 	}else{
								// 		url = this.$config.baseUrl + url;
								// 	}
								// }
								arr.push({file_path:url,file_name:res.data.goods_name+'-授权书.png',type:'img'});
								show_arr.push({file_path:url,file_name:res.data.goods_name+'-授权书.png',type:'img'});
							})
						}
						// if(res.data.auth_letter_template_list.length){
						// 	res.data.auth_letter_template_list.forEach(item=>{
						// 		show_arr.push({content:item.content,type:'text'});
						// 	})
						// }
						if(res.data.file_data.img_list.length){
							res.data.file_data.img_list.forEach((item,index)=>{
								var url = this.$util.img(item.file_path);
								// if( location.protocol.indexOf('http:') != -1 ){
								// 	if(url.indexOf('https:') != -1){
								// 		url = url.replace('https:','http:');
								// 	}
								// }else{
								// 	if(url.indexOf('http:') != -1){
								// 		url = url.replace('http:','https:');
								// 	}
								// }
								arr.push({file_path:url,file_name:res.data.goods_name+'-证书'+(index+1)+'.'+url.split('.')[url.split('.').length-1],type:'img'});
								show_arr.push({file_path:url,file_name:item.file_name,type:'img'});
							});
						}
						if(res.data.file_data.pdf_list.length){
							res.data.file_data.pdf_list.forEach((item,index)=>{
								var url = item.file_path;
								// if(url.indexOf("http://") == -1 && url.indexOf("https://") == -1){
								// 	url = Config.imgDomain + "/" + item.file_path;
								// }else{
								// 	if( location.protocol.indexOf('http:') != -1 ){
								// 		if(url.indexOf('https:') != -1){
								// 			url = url.replace('https:','http:');
								// 		}
								// 	}else{
								// 		if(url.indexOf('http:') != -1){
								// 			url = url.replace('http:','https:');
								// 		}
								// 	}
								// }
								arr.push({file_path:url,file_name:res.data.goods_name+'-证书'+(index+1)+'.pdf',type:'pdf'});
								show_arr.push({file_path:url,file_name:item.file_name,type:'pdf'});
							});
						}
						this.current_order_file = arr;
						var index = 0;
						// 展示数据整理成 一屏2个
						this.current_order_file_show = [];
						show_arr.forEach(item=>{
							if(!this.current_order_file_show[index]){
								this.current_order_file_show[index]= [];
								this.current_order_file_show[index].push(item);
							}else if((this.current_order_file_show[index] && this.current_order_file_show[index].length<2) ){
								this.current_order_file_show[index][this.current_order_file_show[index].length] = item;
							}else{
								index++;
								this.current_order_file_show[index]= [];
								this.current_order_file_show[index].push(item);
							}
						})
						this.current_order = res.data;
						this.$forceUpdate();
						//点击编辑请求
						if(!res.data.is_use_template){
							this.current_shop_template_id = this.current_order.auth_letter_template_list[0].shop_template_id;
							this.current_template = this.current_order.auth_letter_template_list[0].content;
							this.current_watermark = this.current_order.auth_letter_template_list[0].watermark;
						}
						
						if(mode=='use'){
							//使用后下载
							if(arr.length){
								arr.forEach(item=>{
									this.downloadFile(item.file_path,item.file_name);
								})
								this.refresh();
							}
						}else{
							order.is_show = true;
						}
						this.$nextTick(()=>{
							if(this.$refs.contentArea){
								this.contentHeight = this.$refs.contentArea[0].offsetWidth / ( 210 / 297 );
							}
						})
						this.templateListLoading = false;
					}
				})
			},
			chooseTemplate(data){
				this.current_template = data.content;
				this.current_watermark = data.watermark;
				this.current_shop_template_id = data.shop_template_id;
			},
			downloadFile(url, fileName){
				var src = url;
				// if(src.indexOf('www.mling.cn') != -1){
				// 	src = src.replace('https://www.mling.cn/',this.$config.imgDomain)
				// }
				const xhr = new XMLHttpRequest();
				xhr.open('GET', src);
				xhr.responseType = 'blob';
				xhr.onload = function() {
					const blob = xhr.response;
					const link = document.createElement('a');
					link.href = URL.createObjectURL(blob);
					link.download = fileName;
					link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
					link.click();
				};
				xhr.send();
			},
			downFile(){
				if(this.current_order.is_use_template){
					if(this.current_order_file.length){
						this.current_order_file.forEach(item=>{
							this.downloadFile(item.file_path,item.file_name);
						})
					}
				}else{
					useTemplate({
						order_id:this.current_order.order_id,
						id:this.curTemplateId,
					})
					.then(res=>{
						if(res.code>=0){
							this.getOrderDetail('use');
						}else{
							this.$message.error(res.message);
						}
					})
					.catch(err=>{
						this.$message.error(err.message);
					})
				}
			},
			getAccreditList(){
				orderList({
					page:this.currentPage,
					page_size:this.page_size,
					order_status:this.status,
					role:this.role
				})
				.then(res=>{
					this.total = res.data.count;
					this.accredit_list = res.data.list;
					this.accredit_list.forEach(item=>{
						this.$set(item,'is_show',false);
					})
					this.loading = false;
				})
			},
			closeAccredit(data){
				data.is_show = false;
			},
			changeTemplate(data){
				this.curAuthTemplateImg = data.auth_letter_img;
				this.curTemplateId = data.id;
			},
			getOrderAuthLetterList(){
				orderAuthLetterList({
					order_id: this.current_order.order_id
				})
				.then(res=>{
					if(res.code>=0){
						this.authTemplateList = res.data;
						this.curAuthTemplateImg = res.data[0].auth_letter_img;
						this.curTemplateId = res.data[0].id;
						this.templateListLoading = false;
					}
				})
			},
			getCheckIsComplete(){
				var timer = setInterval(()=>{
					checkIsComplete({
						order_id: this.current_order.order_id
					})
					.then(res=>{
						if(res.data){
							// 已完成
							clearInterval(timer);
							this.getOrderAuthLetterList();
						}else{
							// 未完成
						}
					})
				},1000)
			},
			getHasAuthletter(){
				// 获取是否需要生成授权书图片
				hasAuthLetter({
					order_id:this.current_order.order_id
				})
				.then(res=>{
					if(res.code >= 0){
						if(res.data.is_need){
							this.iframe_src = res.data.iframe_src;
							this.getCheckIsComplete()
						}else{
							this.getOrderAuthLetterList();
						}
					}
				})
				.catch(err=>{
					this.$message.error(err.message);
				})
			},
			toShowAccredit(data){
				this.templateListLoading = true;
				this.current_order = data;
				if(this.current_order.is_use_template){
					this.getOrderDetail('',data);
				}else{
					this.getHasAuthletter();
				}
				this.accredit_list.forEach(item=>{
					if(item.order_id == data.order_id){
						item.is_show = true;
					}else{
						item.is_show = false;
					}
				})
			},
			handlePageSizeChange(size) {
				this.page_size = size;
				this.refresh();
			},
			handleCurrentPageChange(page) {
				this.currentPage = page;
				this.refresh();
			},
			refresh(){
				this.loading = true;
				this.getAccreditList();
			},
			chooseStatus(item){
				this.status = item.id;
				this.refresh();
			},
			getAccreditStatus(){
				orderStatus()
				.then(res=>{
					if(res.code>=0){
						this.status_list = [{id:'all',name:'全部'}];
						this.status_list.push(...res.data);
					}
				})
			},
		},
	}
</script>

<style lang="scss">
	.pay_qrcode{
		.el-dialog{
			margin-top: 0 !important;
			top: 50%;
			transform: translateY(-50%);
			max-width: 554px;
			border-radius: 10px;
		}
		.el-dialog__header{
			padding:0;
		}
		.el-dialog__body{
			position: relative;
			padding: 76px 134px 86px;
			border-radius: 10px;
			background-color: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.tit{
				width: 100%;
				border-bottom: 1px solid rgba(48, 55, 61, 0.10);
				color: #30373D;
				font-size: 19px;
				line-height: 23px;
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-bottom: 25px;
				position: relative;
				.line{
					width: 34px;
					height: 2px;
					background-color: #30373D;
					border-radius: 6px;
					position: absolute;
					bottom: 0;
					transform: translateY(50%);
				}
			}
			.qrcode{
				width: 224px;
				border-radius: 3px;
				display: block;
				margin-top: 30px;
				height: 224px;
			}
			.tips{
				margin-top: 30px;
				color: #999;
				font-size: 13px;
				line-height: 15px;
			}
			.el-icon-error{
				color: rgba(48, 55, 61, 0.50);
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				right: 20px;
				top: 20px;
				cursor: pointer;
			}
		}
	}
	.accredit_info{
		.el-carousel__container{
			height: 747px;
		}
		.el-carousel__item{
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.el-carousel__indicators{
			display: none;
		}
	}
	.accredit_list .pager{
		margin-top: 30px;
		.el-pagination{
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			.btn-prev{
				margin-right: 15px !important;
			}
			.btn-next{
				margin-left: 15px !important;
			}
			.btn-prev,.btn-next{
				min-width: 20px;
				height: 20px;
				margin: 0;
				color: #30373D !important;
				font-weight: bolder !important;
				background-color: transparent;
			}
			.el-pagination__jump{
				margin-left: 20px;
				color: #86909C;
				font-size: 14px;
				line-height: 11px;
				display: flex;
				align-items: center;
				height: auto;
				.el-pagination__editor{
					margin: 0 8px;
					padding: 0;
					height: auto;
					width: auto;
					.el-input__inner{
						height:auto;
						min-width: auto;
						border: none;
						color: #30373D;
						font-size: 14px;
						padding: 3px 2px 2px;
						line-height: 14px;
					}
				}
			}
			.el-pagination__total{
				margin-left: 10px;
				color: #86909C;
				font-size: 14px;
				line-height: 21px;
				height: 21px;
			}
			.el-pager{
				display: flex;
				align-items: center;
				.btn-quicknext,.btn-quickprev{
					height: 21px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: transparent;
				}
				.number{
					padding: 0;
					color: #30373D;
					line-height: 11px;
					height: auto;
					min-width: auto;
					padding: 5px 6px;
					font-size: 14px;
					margin: 0;
					font-weight: 500;
					margin-right: 10px;
					background-color: transparent;
					&:last-child{
						margin-right: 0;
					}
					&:not(.disabled).active{
						color: #30373D;
						font-weight: bolder;
						background-color: #fff;
						border-radius: 1px;
					}
				}
			}
		}
	}
</style>
<style lang="scss" scoped>
	.accredit_list{
		.zzc{
			background-color: transparent;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.accredit_info{
			margin-top: 30px;
			padding: 30px 0;
			background-color: #fff;
			border-radius: 10px;
			position: relative;
			z-index: 10;
			.choose_content{
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				height: 680px;
				box-sizing: border-box;
				.template_content{
					background-color: #F7F8FB;
					border-radius: 5px;
					margin: 0 239px;
					flex: 1;
					height: 100%;
					box-sizing: border-box;
					overflow: auto;
					&::-webkit-scrollbar {
						width: 10px;
					}
					&::-webkit-scrollbar-thumb {
						 background-color: #d0d2d5;
					}
					&::-webkit-scrollbar-thumb:hover{
						background: rgba(0, 0, 0, 0.2)
					}
					&::-webkit-scrollbar-track {
						background: rgba(0, 0, 0, 0.1)
					}
					&::-webkit-scrollbar-corner {
						background: springgreen;
					}
					.content-area{
						width: 100%;
						height: 100%;
						position: relative;
						.content{
							height: 100%;
							box-sizing: border-box;
							padding: 30px;
							div{
								width: 100% !important;
							}
						}
						.content-bg{
							background-size: 100% 100%;
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							opacity: 0.2;
							background-repeat: no-repeat;
							pointer-events: none;
						}
					}
					
				}
				.template_list{
					width: 153px;
					padding-left: 30px;
					height: 100%;
					padding-right: 10px;
					overflow: auto;
					&::-webkit-scrollbar {
					    width: 10px;
					}
					&::-webkit-scrollbar-thumb {
						 background-color: #d0d2d5;
					}
					&::-webkit-scrollbar-thumb:hover{
						background: rgba(0, 0, 0, 0.2)
					}
					&::-webkit-scrollbar-track {
					    background: rgba(0, 0, 0, 0.1)
					}
					&::-webkit-scrollbar-corner {
					    background: springgreen;
					}
					.template_tit{
						text-align: center;
						color: #30373D;
						font-weight: 600;
						font-size: 18px;
						line-height: 18px;
					}
					.item{
						margin-top: 30px;
						width: 100%;
						background-color: #F7F8FB;
						border-radius: 5px;
						height: 93px;
						position: relative;
						cursor: pointer;
						border: 1px solid transparent;
						box-sizing: border-box;
						&.active{
							.name{
								color: #fefefe;
								background-color: #30373D;
							}
						}
						.pic{
							width: 100%;
							height: 100%;
							position: relative;
							border-radius: 5px;
							.pic_bg{
								position: absolute;
								top: 0;
								left: 0;
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 5px;
							}
						}
						.name{
							width: 100%;
							text-align: center;
							position: absolute;
							color: #30373D;
							font-size: 15px;
							line-height: 15px;
							bottom: 10px;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}
			.content{
				padding: 0 30px;
				.data_item{
					width: calc( ( 100% - 64px ) / 2 );
					box-sizing: border-box;
					background-color: #F7F8FB;
					border-radius: 10px;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;
					color: #30373D;
					font-size: 16px;
					img{
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
			.actions{
				margin-top: 30px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-top: 1px solid rgba(48, 55, 61, 0.10);
				padding-top: 30px;
				.action{
					cursor: pointer;
					padding: 6px 0;
					min-width: 116px;
					justify-content: center;
					display: flex;
					align-items: center;
					color: rgba(48, 55, 61, 0.50);
					font-size: 15px;
					line-height: 21px;
					background-color: #F7F8FB;
					border-radius: 5px;
					margin-right: 30px;
					&:last-child{
						margin-right: 0;
					}
					&:hover{
						color: #fff;
						background-color: #30373D;
					}
				}
			}
			.trademark{
				width: calc( ( 100% - 64px ) / 2 );
				padding: 100px 30px;
				box-sizing: border-box;
				background-color: #F7F8FB;
				border-radius: 10px;
				height: 100%;
			}
			.accredit_content{
				width: calc( ( 100% - 64px ) / 2 );
				padding: 100px 30px;
				box-sizing: border-box;
				background-color: #F7F8FB;
				border-radius: 10px;
				height: 100%;
			}
		}
		.list_item{
			display: flex;
			align-items: center;
			padding: 20px 30px 20px 20px;
			background-color: #fff;
			border-radius: 10px;
			margin-top: 20px;
			.item{
				flex: 1;
				font-size: 15px;
				line-height: 21px;
				color: #30373D;
				&:last-child{
					flex: none;
					position: relative;
					z-index: 10;
					cursor: pointer;
				}
				&.line{
					flex: none;
					width: 28%;
				}
			}
		}
		.list_tit{
			display: flex;
			align-items: center;
			padding: 20px 30px 20px 20px;
			background-color: #fff;
			border-radius: 10px;
			margin-top: 30px;
			.item{
				flex: 1;
				font-size: 15px;
				line-height: 21px;
				color: #30373D;
				font-weight: bold;
				&:last-child{
					flex: none;
				}
				&.line{
					flex: none;
					width: 28%;
				}
			}
		}
		.status_list{
			display: flex;
			align-items: center;
			justify-content: center;
			span {
				font-size: 19px;
				font-weight: 500;
				color: #30373D;
				line-height: 23px;
				cursor: pointer;
			    padding-bottom: 9px;
				margin-right: 58px;
				position: relative;
				&.active{
					color: #FF3300;
					&::after {
					    content: "";
					    position: absolute;
					    bottom: 0;
					    height: 3px;
					    background-color: #FF3300;
					    width: 34px;
					    border-radius: 2px;
					    left: 50%;
					    transform: translateX(-50%);
					}
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	.iframe-style{
		width: 0;
		height: 0;
		border: none;
	}
</style>